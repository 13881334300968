/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-loop-func */
import React from 'react';
import { createContext, useContext } from 'react';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from 'lodash';

import axios from '../../../../utils/axios';
import { ReadAPIParams, successAPI, errorAPI, removeEmptyRows } from '../../../../utils/utils';
import Yup from '../../../../utils/yup';
import { VehicleChecklistOptionType } from 'src/utils/enums';


export const fields = {
  preCheckName: {
    id: 'preCheckName',
    label: 'Pre-check',
    placeholder: 'Enter pre-check name',
  },
  postCheckName: {
    id: 'postCheckName',
    label: 'Post-check',
    placeholder: 'Enter post-check locations',
  },
};

export const formSchema = (id: number|null= null) => {
  return Yup.object().shape({
    preCheck: Yup.array().of(Yup.object({
      name: Yup.string().nullable().label(fields.preCheckName.label),
    }))
    .unique('name'),
    postCheck: Yup.array().of(Yup.object({
      name: Yup.string().nullable().label(fields.postCheckName.label),
    }))
    .unique('name'),
  })
}


let formikContext: any = null;
export const FormikContext = createContext<any>(null);
export const useFormikContext = () => {
    formikContext = useContext(FormikContext);
    if (!formikContext) {
      throw new Error('useFormikContext must be used within a FormikProvider');
    }
    return formikContext;
};


export const prepareForm = (preCheck: any = null, postCheck: any = null) => {
  let formPreCheck = _.cloneDeep(preCheck);
  let formPostCheck = _.cloneDeep(postCheck);
  
  let data: any = {
    preCheck: [],
    postCheck: [],
  }
  

  if(formPreCheck){
    data['preCheck'] = (formPreCheck && formPreCheck.length > 0) ? formPreCheck : [];
  }
  let initPre = _.cloneDeep(initialChecklistItem);
  initPre.type = VehicleChecklistOptionType.PreCheck;
  data['preCheck'].push(initPre)

  if(formPostCheck){
    data['postCheck'] = (formPostCheck && formPostCheck.length > 0) ? formPostCheck : [];
  }
  let initPost = _.cloneDeep(initialChecklistItem);
  initPost.type = VehicleChecklistOptionType.PostCheck;
  data['postCheck'].push(initPost)
  

  return data;
};
export const preparePreCheckForm = (preCheck: any = null, postCheck: any = null) => {
  let formPreCheck = _.cloneDeep(preCheck);
  let formPostCheck = _.cloneDeep(postCheck);
  
  let data: any = {
    preCheck: [],
    postCheck: formPostCheck,
  }

  
  if(formPreCheck){
    data['preCheck'] = (formPreCheck && formPreCheck.length > 0) ? formPreCheck : [];
  }
  let init = _.cloneDeep(initialChecklistItem);
  init.type = VehicleChecklistOptionType.PreCheck;
  data['preCheck'].push(init)


  return data;
};
export const preparePostCheckForm = (postCheck: any = null, preCheck: any = null) => {
  let formPreCheck = _.cloneDeep(preCheck);
  let formPostCheck = _.cloneDeep(postCheck);
  
  let data: any = {
    preCheck: formPreCheck,
    postCheck: [],
  }


  if(formPostCheck){
    data['postCheck'] = (formPostCheck && formPostCheck.length > 0) ? formPostCheck : [];
  }
  let init = _.cloneDeep(initialChecklistItem);
  init.type = VehicleChecklistOptionType.PostCheck;
  data['postCheck'].push(init)
  

  return data;
};
export const preparePreCheckData = (values: any = null) => {
  let data: any = [];

  if(values && values.preCheck && values.preCheck.length > 0){
    data = values.preCheck.map((item: any, i: number) => {
      let itm: any = {
        name: item?.name,
        type: item?.type,
      }
      if(item.vehicleMaintenanceChecklistOptionId){
        itm['vehicleMaintenanceChecklistOptionId'] = item?.vehicleMaintenanceChecklistOptionId;
      }

      return itm
    });
  }
  if(data && data.length > 0){
    data = removeEmptyRows(data, [ 'name' ]);
  }

  return {
    type: VehicleChecklistOptionType.PreCheck,
    data: data,
  };
};
export const preparePostCheckData = (values: any = null) => {
  let data: any = [];

  if(values && values.postCheck && values.postCheck.length > 0){
    data = values.postCheck.map((item: any, i: number) => {
      let itm: any = {
        name: item?.name,
        type: item?.type,
      }
      if(item.vehicleMaintenanceChecklistOptionId){
        itm['vehicleMaintenanceChecklistOptionId'] = item?.vehicleMaintenanceChecklistOptionId;
      }

      return itm
    });
  }
  if(data && data.length > 0){
    data = removeEmptyRows(data, [ 'name' ]);
  }

  return {
    type: VehicleChecklistOptionType.PostCheck,
    data: data,
  };
};


export interface initialValuesStruct {
  preCheck: Array<any>,
  postCheck: Array<any>,
};
export const initialValues: initialValuesStruct = {
  preCheck: [],
  postCheck: [],
};

export interface ChecklistItemStruct {
  vehicleMaintenanceChecklistOptionId?: number|null,
  name: string,
  type: number|null,
};
export const initialChecklistItem: ChecklistItemStruct = {
  vehicleMaintenanceChecklistOptionId: null,
  name: '',
  type: null,
};


interface InitState {
  isLoadingPreCheck: boolean,
  isLoadingPostCheck: boolean,
  show: boolean,
  details: any,
  listPreCheck: Array<any>,
  listPostCheck: Array<any>,

  isLoadingCreateUpdateDelete: boolean,
}

function NewReducer() {
  const name = 'checklistSettingsSlice';


  const initialState: InitState = {
    isLoadingPreCheck: false,
    isLoadingPostCheck: false,
    show: false,
    details: initialValues,
    listPreCheck: [],
    listPostCheck: [],

    isLoadingCreateUpdateDelete: false,
  };


  const reducers = {
    resetSlice: () => {
      return initialState;
    },
    setLoadingPreCheck: (state: InitState, action: PayloadAction<boolean>) => {
      state.isLoadingPreCheck = action.payload;
    },
    setLoadingPostCheck: (state: InitState, action: PayloadAction<boolean>) => {
      state.isLoadingPostCheck = action.payload;
    },
    setShow: (state: InitState, action: PayloadAction<{ show: boolean }>) => {
      state.show = action.payload.show;
    },
    setValues: (state: InitState, action: PayloadAction<any>) => {
      state.details = action.payload;
    },

    startReadPreCheck: (state: InitState) => {
      state.isLoadingPreCheck = true;
      state.listPreCheck = [];
    },
    finishReadPreCheck: (state: InitState, action: PayloadAction<any>) => {
      let data = (action.payload && action.payload.length > 0) ? action.payload : [];
      state.listPreCheck = data;
      state.isLoadingPreCheck = false;
    },

    startDetailsPreCheck: (state: InitState) => {
      state.isLoadingPreCheck = true;
    },
    finishDetailsPreCheck: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingPreCheck = false;
      state.details = action.payload;
    },

    startCreatePreCheck: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishCreatePreCheck: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },

    startUpdatePreCheck: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishUpdatePreCheck: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },

    startDeletePreCheck: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishDeletePreCheck: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },
    
    startBulkPreCheck: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishBulkPreCheck: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },
    
    
    startReadPostCheck: (state: InitState) => {
      state.isLoadingPostCheck = true;
      state.listPostCheck = [];
    },
    finishReadPostCheck: (state: InitState, action: PayloadAction<any>) => {
      let data = (action.payload && action.payload.length > 0) ? action.payload : [];
      state.listPostCheck = data;
      state.isLoadingPostCheck = false;
    },

    startDetailsPostCheck: (state: InitState) => {
      state.isLoadingPostCheck = true;
    },
    finishDetailsPostCheck: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingPostCheck = false;
      state.details = action.payload;
    },

    startCreatePostCheck: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishCreatePostCheck: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },

    startUpdatePostCheck: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishUpdatePostCheck: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },

    startDeletePostCheck: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishDeletePostCheck: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },
    
    startBulkPostCheck: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishBulkPostCheck: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },
  };


  const apis = {
    callReadPreCheckApi: (params: ReadAPIParams, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startReadPreCheck());
  
        await axios.get('vehicle/checklist/option', { params: params }).then(result => {
            let data = (result && result.data && result.data.data && result.data.data.length > 0) ? result.data.data : [];
            
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishReadPreCheck(data));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishReadPreCheck(null));
        });
    },

    callDetailsPreCheckApi: (id: number|null, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startDetailsPreCheck());
  
        await axios.get('vehicle/checklist/option/' + id).then(result => {
            let data = result.data;
            
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishDetailsPreCheck(data));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishDetailsPreCheck(null));
        });
    },

    callCreatePreCheckApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startCreatePreCheck());
  
        await axios.post('vehicle/checklist/option', params).then(result => {
            let data = result.data;
            
            successAPI(data);

            let obj = (data && data.data) ? data.data : null;
            callback(true, obj);
            dispatch(actions.finishCreatePreCheck(obj));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishCreatePreCheck(null));
        });
    },

    callUpdatePreCheckApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startUpdatePreCheck());
  
        await axios.put('vehicle/checklist/option', params).then(result => {
            let data = result.data;
            
            successAPI(data);

            let obj = (data && data.data) ? data.data : null;
            callback(true, obj);
            dispatch(actions.finishUpdatePreCheck(obj));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishUpdatePreCheck(null));
        });
    },

    callDeletePreCheckApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startDeletePreCheck());
  
        await axios.delete('vehicle/checklist/option', { data: params }).then(result => {
            let data = result.data;
                
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishDeletePreCheck(data));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishDeletePreCheck(null));
        });
    },

    callBulkPreCheckApi: (params: any, callback: (state: boolean, data: any, message: any) => void) => async (dispatch: any) => {
        dispatch(actions.startBulkPreCheck());
  
        await axios.put('vehicle/checklist/option/bulk', params).then(result => {
            let data = result.data;
            
            // successAPI(data);

            let obj = (data && data.data) ? data.data : null;
            let msg = (data && data.message) ? data.message : null;
            callback(true, obj, msg);
            dispatch(actions.finishBulkPreCheck(obj));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null, null);
            dispatch(actions.finishBulkPreCheck(null));
        });
    },


    callReadPostCheckApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startReadPostCheck());

      await axios.get('vehicle/checklist/option', { params: params }).then(result => {
          let data = (result && result.data && result.data.data && result.data.data.length > 0) ? result.data.data : [];
          
          successAPI(data);

          callback(true, data);
          dispatch(actions.finishReadPostCheck(data));
      }).catch(error => {
          errorAPI(error);
          
          callback(false, null);
          dispatch(actions.finishReadPostCheck(null));
      });
    },

    callDetailsPostCheckApi: (id: number|null, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startDetailsPostCheck());

      await axios.get('vehicle/checklist/option/' + id).then(result => {
          let data = result.data;
          
          successAPI(data);

          callback(true, data);
          dispatch(actions.finishDetailsPostCheck(data));
      }).catch(error => {
          errorAPI(error);
          
          callback(false, null);
          dispatch(actions.finishDetailsPostCheck(null));
      });
    },

    callCreatePostCheckApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startCreatePostCheck());

      await axios.post('vehicle/checklist/option', params).then(result => {
          let data = result.data;
          
          successAPI(data);

          let obj = (data && data.data) ? data.data : null;
          callback(true, obj);
          dispatch(actions.finishCreatePostCheck(obj));
      }).catch(error => {
          errorAPI(error);
          
          callback(false, null);
          dispatch(actions.finishCreatePostCheck(null));
      });
    },

    callUpdatePostCheckApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startUpdatePostCheck());

      await axios.put('vehicle/checklist/option', params).then(result => {
          let data = result.data;
          
          successAPI(data);

          let obj = (data && data.data) ? data.data : null;
          callback(true, obj);
          dispatch(actions.finishUpdatePostCheck(obj));
      }).catch(error => {
          errorAPI(error);
          
          callback(false, null);
          dispatch(actions.finishUpdatePostCheck(null));
      });
    },

    callDeletePostCheckApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startDeletePostCheck());

      await axios.delete('vehicle/checklist/option', { data: params }).then(result => {
          let data = result.data;
              
          successAPI(data);

          callback(true, data);
          dispatch(actions.finishDeletePostCheck(data));
      }).catch(error => {
          errorAPI(error);
          
          callback(false, null);
          dispatch(actions.finishDeletePostCheck(null));
      });
    },

    callBulkPostCheckApi: (params: any, callback: (state: boolean, data: any, message: any) => void) => async (dispatch: any) => {
        dispatch(actions.startBulkPostCheck());
  
        await axios.put('vehicle/checklist/option/bulk', params).then(result => {
            let data = result.data;
            
            // successAPI(data);

            let obj = (data && data.data) ? data.data : null;
            let msg = (data && data.message) ? data.message : null;
            callback(true, obj, msg);
            dispatch(actions.finishBulkPostCheck(obj));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null, null);
            dispatch(actions.finishBulkPostCheck(null));
        });
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();