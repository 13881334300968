/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-loop-func */
import React from 'react';
import { createContext, useContext } from 'react';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from 'lodash';

import axios from '../../../../utils/axios';
import { ReadAPIParams, successAPI, errorAPI, removeEmptyRows } from '../../../../utils/utils';
import Yup from '../../../../utils/yup';
import { MaintenanceStoreType } from 'src/utils/enums';


export const fields = {
  storeName: {
    id: 'name',
    label: 'Store',
    placeholder: 'Enter store name',
  },
  tireLocationName: {
    id: 'name',
    label: 'Tire Locations',
    placeholder: 'Enter tire locations',
  },
};

export const formSchema = (id: number|null= null) => {
  return Yup.object().shape({
    store: Yup.array().of(Yup.object({
      name: Yup.string().nullable().label(fields.storeName.label),
    }))
    .unique('name'),
    tireLocation: Yup.array().of(Yup.object({
      name: Yup.string().nullable().label(fields.tireLocationName.label),
    }))
    .unique('name'),
  })
}


let formikContext: any = null;
export const FormikContext = createContext<any>(null);
export const useFormikContext = () => {
    formikContext = useContext(FormikContext);
    if (!formikContext) {
      throw new Error('useFormikContext must be used within a FormikProvider');
    }
    return formikContext;
};


export const prepareForm = (store: any = null, tireLocation: any = null) => {
  let formStore = _.cloneDeep(store);
  let formTireLocation = _.cloneDeep(tireLocation);
  
  let data: any = {
    store: [],
    tireLocation: [],
  }
  

  if(formStore){
    data['store'] = (formStore && formStore.length > 0) ? formStore : [];
  }
  data['store'].push(initialStoreItem)

  if(formTireLocation){
    data['tireLocation'] = (formTireLocation && formTireLocation.length > 0) ? formTireLocation : [];
  }
  data['tireLocation'].push(initialTireLocationItem)
  

  return data;
};
export const prepareStoreForm = (store: any = null, tireLocation: any = null) => {
  let formStore = _.cloneDeep(store);
  let formTireLocation = _.cloneDeep(tireLocation);
  
  let data: any = {
    store: [],
    tireLocation: formTireLocation,
  }

  
  if(formStore){
    data['store'] = (formStore && formStore.length > 0) ? formStore : [];
  }
  data['store'].push(initialStoreItem)


  return data;
};
export const prepareTireLocationForm = (tireLocation: any = null, store: any = null) => {
  let formStore = _.cloneDeep(store);
  let formTireLocation = _.cloneDeep(tireLocation);
  
  let data: any = {
    store: formStore,
    tireLocation: [],
  }


  if(formTireLocation){
    data['tireLocation'] = (formTireLocation && formTireLocation.length > 0) ? formTireLocation : [];
  }
  data['tireLocation'].push(initialTireLocationItem)
  

  return data;
};
export const prepareStoreData = (values: any = null) => {
  let data: any = [];

  if(values && values.store && values.store.length > 0){
    data = values.store.map((item: any, i: number) => {
      let itm: any = {
        name: item?.name,
        type: item?.type,
      }
      if(item.maintenanceStoreId){
        itm['maintenanceStoreId'] = item?.maintenanceStoreId;
      }

      return itm
    });
  }
  if(data && data.length > 0){
    data = removeEmptyRows(data, [ 'name' ]);
  }

  return {
    type: MaintenanceStoreType.Tire,
    data: data,
  };
};
export const prepareTireLocationData = (values: any = null) => {
  let data: any = [];

  if(values && values.tireLocation && values.tireLocation.length > 0){
    data = values.tireLocation.map((item: any, i: number) => {
      let itm: any = {
        name: item?.name,
      }
      if(item.vehicleTireLocationId){
        itm['vehicleTireLocationId'] = item?.vehicleTireLocationId;
      }

      return itm
    });
  }
  if(data && data.length > 0){
    data = removeEmptyRows(data, [ 'name' ]);
  }

  return {
    data: data,
  };
};


export interface initialValuesStruct {
  store: Array<any>,
  tireLocation: Array<any>,
};
export const initialValues: initialValuesStruct = {
  store: [],
  tireLocation: [],
};

export interface StoreItemStruct {
  maintenanceStoreId?: number|null,
  name: string,
  type: number,
};
export const initialStoreItem: StoreItemStruct = {
  maintenanceStoreId: null,
  name: '',
  type: MaintenanceStoreType.Tire,
};

export interface TireLocationItem {
  vehicleTireLocationId?: number|null,
  name: string,
};
export const initialTireLocationItem: TireLocationItem = {
  vehicleTireLocationId: null,
  name: '',
};


interface InitState {
  isLoadingStore: boolean,
  isLoadingTireLocation: boolean,
  show: boolean,
  details: any,
  listStore: Array<any>,
  listTireLocation: Array<any>,

  isLoadingCreateUpdateDelete: boolean,
}

function NewReducer() {
  const name = 'tireSettingsSlice';


  const initialState: InitState = {
    isLoadingStore: false,
    isLoadingTireLocation: false,
    show: false,
    details: initialValues,
    listStore: [],
    listTireLocation: [],

    isLoadingCreateUpdateDelete: false,
  };


  const reducers = {
    resetSlice: () => {
      return initialState;
    },
    setLoadingStore: (state: InitState, action: PayloadAction<boolean>) => {
      state.isLoadingStore = action.payload;
    },
    setLoadingTireLocation: (state: InitState, action: PayloadAction<boolean>) => {
      state.isLoadingTireLocation = action.payload;
    },
    setShow: (state: InitState, action: PayloadAction<{ show: boolean }>) => {
      state.show = action.payload.show;
    },
    setValues: (state: InitState, action: PayloadAction<any>) => {
      state.details = action.payload;
    },

    startReadStore: (state: InitState) => {
      state.isLoadingStore = true;
      state.listStore = [];
    },
    finishReadStore: (state: InitState, action: PayloadAction<any>) => {
      let data = (action.payload && action.payload.length > 0) ? action.payload : [];
      state.listStore = data;
      state.isLoadingStore = false;
    },

    startDetailsStore: (state: InitState) => {
      state.isLoadingStore = true;
    },
    finishDetailsStore: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingStore = false;
      state.details = action.payload;
    },

    startCreateStore: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishCreateStore: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },

    startUpdateStore: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishUpdateStore: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },

    startDeleteStore: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishDeleteStore: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },
    
    startBulkStore: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishBulkStore: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },
    
    
    startReadTireLocation: (state: InitState) => {
      state.isLoadingTireLocation = true;
      state.listTireLocation = [];
    },
    finishReadTireLocation: (state: InitState, action: PayloadAction<any>) => {
      let data = (action.payload && action.payload.length > 0) ? action.payload : [];
      state.listTireLocation = data;
      state.isLoadingTireLocation = false;
    },

    startDetailsTireLocation: (state: InitState) => {
      state.isLoadingTireLocation = true;
    },
    finishDetailsTireLocation: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingTireLocation = false;
      state.details = action.payload;
    },

    startCreateTireLocation: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishCreateTireLocation: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },

    startUpdateTireLocation: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishUpdateTireLocation: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },

    startDeleteTireLocation: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishDeleteTireLocation: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },
    
    startBulkTireLocation: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishBulkTireLocation: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },
  };


  const apis = {
    callReadStoreApi: (params: ReadAPIParams, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startReadStore());
  
        await axios.get('maintenance-store', { params: params }).then(result => {
            let data = (result && result.data && result.data.data && result.data.data.length > 0) ? result.data.data : [];
            
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishReadStore(data));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishReadStore(null));
        });
    },

    callDetailsStoreApi: (id: number|null, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startDetailsStore());
  
        await axios.get('maintenance-store/' + id).then(result => {
            let data = result.data;
            
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishDetailsStore(data));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishDetailsStore(null));
        });
    },

    callCreateStoreApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startCreateStore());
  
        await axios.post('maintenance-store', params).then(result => {
            let data = result.data;
            
            successAPI(data);

            let obj = (data && data.data) ? data.data : null;
            callback(true, obj);
            dispatch(actions.finishCreateStore(obj));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishCreateStore(null));
        });
    },

    callUpdateStoreApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startUpdateStore());
  
        await axios.put('maintenance-store', params).then(result => {
            let data = result.data;
            
            successAPI(data);

            let obj = (data && data.data) ? data.data : null;
            callback(true, obj);
            dispatch(actions.finishUpdateStore(obj));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishUpdateStore(null));
        });
    },

    callDeleteStoreApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startDeleteStore());
  
        await axios.delete('maintenance-store', { data: params }).then(result => {
            let data = result.data;
                
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishDeleteStore(data));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishDeleteStore(null));
        });
    },

    callBulkStoreApi: (params: any, callback: (state: boolean, data: any, message: any) => void) => async (dispatch: any) => {
        dispatch(actions.startBulkStore());
  
        await axios.put('maintenance-store/bulk', params).then(result => {
            let data = result.data;
            
            // successAPI(data);

            let obj = (data && data.data) ? data.data : null;
            let msg = (data && data.message) ? data.message : null;
            callback(true, obj, msg);
            dispatch(actions.finishBulkStore(obj));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null, null);
            dispatch(actions.finishBulkStore(null));
        });
    },


    callReadTireLocationApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startReadTireLocation());

      await axios.get('vehicle/tire-location', { params: params }).then(result => {
          let data = (result && result.data && result.data.data && result.data.data.length > 0) ? result.data.data : [];
          
          successAPI(data);

          callback(true, data);
          dispatch(actions.finishReadTireLocation(data));
      }).catch(error => {
          errorAPI(error);
          
          callback(false, null);
          dispatch(actions.finishReadTireLocation(null));
      });
    },

    callDetailsTireLocationApi: (id: number|null, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startDetailsTireLocation());

      await axios.get('vehicle/tire-location/' + id).then(result => {
          let data = result.data;
          
          successAPI(data);

          callback(true, data);
          dispatch(actions.finishDetailsTireLocation(data));
      }).catch(error => {
          errorAPI(error);
          
          callback(false, null);
          dispatch(actions.finishDetailsTireLocation(null));
      });
    },

    callCreateTireLocationApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startCreateTireLocation());

      await axios.post('vehicle/tire-location', params).then(result => {
          let data = result.data;
          
          successAPI(data);

          let obj = (data && data.data) ? data.data : null;
          callback(true, obj);
          dispatch(actions.finishCreateTireLocation(obj));
      }).catch(error => {
          errorAPI(error);
          
          callback(false, null);
          dispatch(actions.finishCreateTireLocation(null));
      });
    },

    callUpdateTireLocationApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startUpdateTireLocation());

      await axios.put('vehicle/tire-location', params).then(result => {
          let data = result.data;
          
          successAPI(data);

          let obj = (data && data.data) ? data.data : null;
          callback(true, obj);
          dispatch(actions.finishUpdateTireLocation(obj));
      }).catch(error => {
          errorAPI(error);
          
          callback(false, null);
          dispatch(actions.finishUpdateTireLocation(null));
      });
    },

    callDeleteTireLocationApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startDeleteTireLocation());

      await axios.delete('vehicle/tire-location', { data: params }).then(result => {
          let data = result.data;
              
          successAPI(data);

          callback(true, data);
          dispatch(actions.finishDeleteTireLocation(data));
      }).catch(error => {
          errorAPI(error);
          
          callback(false, null);
          dispatch(actions.finishDeleteTireLocation(null));
      });
    },

    callBulkTireLocationApi: (params: any, callback: (state: boolean, data: any, message: any) => void) => async (dispatch: any) => {
        dispatch(actions.startBulkTireLocation());
  
        await axios.put('vehicle/tire-location/bulk', params).then(result => {
            let data = result.data;
            
            // successAPI(data);

            let obj = (data && data.data) ? data.data : null;
            let msg = (data && data.message) ? data.message : null;
            callback(true, obj, msg);
            dispatch(actions.finishBulkTireLocation(obj));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null, null);
            dispatch(actions.finishBulkTireLocation(null));
        });
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();