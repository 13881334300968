/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-loop-func */
import React from 'react';
import { createContext, useContext } from 'react';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from 'lodash';
import moment from 'moment';

import axios from '../../../utils/axios';
import { ReadAPIParams, successAPI, errorAPI, dateFormat, timeFormat, concatDateTime, isNumeric, getBase64, isBase64 } from '../../../utils/utils';
import Yup from '../../../utils/yup';
import { PhotoScanType } from 'src/utils/enums';


export const fields = {
  fuelDispensed: {
    id: 'fuelDispensed',
    label: 'Fuel dispensed',
    placeholder: 'Enter fuel dispensed',
  },
  date: {
    id: 'date',
    label: 'Date',
    placeholder: 'Enter date',
  },
  time: {
    id: 'time',
    label: 'Time',
    placeholder: 'Enter time',
  },
  maintenanceStoreId: {
    id: 'maintenanceStoreId',
    label: 'Store',
    placeholder: 'Choose store',
  },
  vehicleId: {
    id: 'vehicleId',
    label: 'Vehicle',
    placeholder: 'Choose vehicle',
  },
  odometer: {
    id: 'odometer',
    label: 'Mileage',
    placeholder: 'Enter mileage',
  },
  remarks: {
    id: 'remarks',
    label: 'Remarks',
    placeholder: 'Enter remarks',
    rows: 3,
    maxWords: 100,
  },
  photos: {
    id: 'photos',
    label: 'Photo',
    placeholder: '',
    required: 'Photo is a required field',
  },
  GPSOdometer: {
    id: 'GPSOdometer',
    label: 'GPS odometer',
    placeholder: '',
  },
  driver: {
    id: 'driver',
    label: 'Driver',
    placeholder: '',
  },
  cost: {
    id: 'cost',
    label: 'Cost',
    placeholder: '',
  },
};

export const formSchema = (id: number|null= null) => {
  return Yup.object().shape({
    fuelDispensed: Yup.number().nullable().required().label(fields.fuelDispensed.label),
    date: Yup.string().nullable().required().maxDate().label(fields.date.label),
    time: Yup.string().nullable().required().label(fields.time.label),
    maintenanceStoreId: Yup.number().nullable().required().label(fields.maintenanceStoreId.label),
    vehicleId: Yup.number().nullable().required().label(fields.vehicleId.label),
    odometer: Yup.string().nullable().required().label(fields.odometer.label),
    cost: Yup.number().nullable().required().label(fields.cost.label),
    remarks: Yup.string().nullable().maxWords(fields.remarks.maxWords).label(fields.remarks.label),

    photos: Yup.array().when([], () => {
      if(!id){
        return Yup.array().of(Yup.object()).label(fields.photos.label);
      } else {
        return Yup.array().of(Yup.object()).min(1, fields.photos.required).label(fields.photos.label);
      }
    }),
  })
}


let formikContext: any = null;
export const FormikContext = createContext<any>(null);
export const useFormikContext = () => {
    formikContext = useContext(FormikContext);
    if (!formikContext) {
      throw new Error('useFormikContext must be used within a FormikProvider');
    }
    return formikContext;
};


export const prepareForm = (values: any = null, defValues: any = null) => {
  let form = _.cloneDeep(values);
  let data = _.cloneDeep(defValues);
  
  if(data && form){
    let vehicleMaintenanceFuelId = (form.vehicleMaintenanceFuelId) ? form.vehicleMaintenanceFuelId : null;

    let fuelDispensed = (isNumeric(form.fuelDispensed)) ? form.fuelDispensed : initialValues.fuelDispensed;
    let odometer = (isNumeric(form.odometer)) ? form.odometer : initialValues.odometer;
    let cost = (isNumeric(form.cost)) ? form.cost : initialValues.cost;
    let remarks = (form.remarks && form.remarks !== '') ? form.remarks : initialValues.remarks;

    let vehicle = (form.vehicle) ? form.vehicle : null;
    let vehicleId = (vehicle && vehicle.vehicleId && vehicle.vehicleId !== '') ? vehicle.vehicleId : initialValues.vehicleId;
    let vehicleName = (vehicle && vehicle.vehicleName && vehicle.vehicleName !== '') ? vehicle.vehicleName : initialValues.vehicleName;
    
    let maintenanceStore = (form.maintenanceStore) ? form.maintenanceStore : null;
    let maintenanceStoreId = (maintenanceStore && maintenanceStore.maintenanceStoreId && maintenanceStore.maintenanceStoreId !== '') ? maintenanceStore.maintenanceStoreId : initialValues.maintenanceStoreId;
    let maintenanceStoreName = (maintenanceStore && maintenanceStore.name && maintenanceStore.name !== '') ? maintenanceStore.name : initialValues.maintenanceStoreName;

    let dispensedDateTime = (form.dispensedDateTime && form.dispensedDateTime !== '') ? form.dispensedDateTime : '';
    let date = (form.dispensedDateTime && form.dispensedDateTime !== '') ? moment(dispensedDateTime).format(dateFormat()) : moment().format(dateFormat());
    let time = (form.dispensedDateTime && form.dispensedDateTime !== '') ? moment(dispensedDateTime).format(timeFormat()) : moment().format(timeFormat());

    let photos: any = [];
    if(form.photos && form.photos.length > 0){
      photos = form.photos.map((file: any, i: number) => {
        return {
          uid: file.vehicleMaintenanceFuelPhotoId,
          name: 'name_' + i,
          status: 'done',
          url: file.photoUrl,
          thumbUrl: file.photoUrl,
          preview: file.photoUrl,
          data: file,
        }
      });
    }

    data['vehicleMaintenanceFuelId'] = vehicleMaintenanceFuelId;

    data['fuelDispensed'] = fuelDispensed;
    data['odometer'] = odometer;
    data['cost'] = cost;
    data['remarks'] = remarks;

    data['vehicleId'] = vehicleId;
    data['vehicleName'] = vehicleName;

    data['maintenanceStoreId'] = maintenanceStoreId;
    data['maintenanceStoreName'] = maintenanceStoreName;
    
    data['date'] = date;
    data['time'] = time;

    data['photos'] = photos;
  }
  
  return data;
};
export const prepareData = (values: any = null) => {
  let data: any = {};

  if(values){
    if(values.vehicleMaintenanceFuelId){
      data['vehicleMaintenanceFuelId'] = values.vehicleMaintenanceFuelId;
    }
    
    data['fuelDispensed'] = values.fuelDispensed;
    data['odometer'] = values.odometer;
    data['cost'] = values.cost;
    data['remarks'] = values.remarks;

    data['vehicleId'] = values.vehicleId;
    data['maintenanceStoreId'] = values.maintenanceStoreId;

    data['dispensedDateTime'] = concatDateTime(values.date, values.time);

    let photos: any = [];
    if(values.photos && values.photos.length > 0){
      photos = values.photos.map((file: any, i: number) => {
        if(isBase64(file?.url)){
          let fileData = getBase64(file);
  
          return {
            base64Image: fileData?.base64,
            fileName: file.name,
            photoScanType: PhotoScanType.Gallery,
          }
        } else {
          return file?.data;
        }
      });
    }
    data['photos'] = photos;
  }

  return data;
};


export interface initialValuesStruct {
  fuelDispensed: string,
  date: string,
  time: string,
  maintenanceStoreId: any,
  maintenanceStoreName: string,
  vehicleId: number|null,
  vehicleName: string,
  odometer: string,
  cost: string,
  photos: Array<any>,
  remarks: string,
};
export const initialValues: initialValuesStruct = {
  fuelDispensed: '',
  date: moment().format(dateFormat()),
  time: moment().format(timeFormat()),
  maintenanceStoreId: null,
  maintenanceStoreName: '',
  vehicleId: null,
  vehicleName: '',
  odometer: '',
  cost: '',
  photos: [],
  remarks: '',
};


interface InitState {
  isLoading: boolean,
  show: boolean,
  id: any|null,
  details: any,

  isLoadingDetails: boolean,
  isLoadingCreateUpdateDelete: boolean,
}

function NewReducer() {
  const name = 'fuelSlice';


  const initialState: InitState = {
    isLoading: false,
    show: false,
    id: null,
    details: initialValues,

    isLoadingDetails: false,
    isLoadingCreateUpdateDelete: false,
  };


  const reducers = {
    resetSlice: () => {
      return initialState;
    },
    setLoading: (state: InitState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setId: (state: InitState, action: PayloadAction<any>) => {
      state.id = action.payload;
    },
    setShow: (state: InitState, action: PayloadAction<{ show: boolean, id: number|null}>) => {
      state.id = action.payload.id;
      state.show = action.payload.show;
    },
    setValues: (state: InitState, action: PayloadAction<any>) => {
      state.details = action.payload;
    },

    startRead: (state: InitState) => {
      state.isLoading = true;
      // state.list = [];
    },
    finishRead: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
      let data = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];
      // state.list = data;
    },

    startDetails: (state: InitState) => {
      state.isLoadingDetails = true;
    },
    finishDetails: (state: InitState, action: PayloadAction<any>) => {
      state.details = action.payload;
      state.isLoadingDetails = false;
    },

    startCreate: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishCreate: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },

    startUpdate: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishUpdate: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },

    startDelete: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishDelete: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },
  };


  const apis = {
    callReadApi: (params: ReadAPIParams, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startRead());
  
        await axios.get('vehicle/maintenance-fuel', { params: params }).then(result => {
            let data = result.data;
            
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishRead(data));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishRead(null));
        });
    },

    callDetailsApi: (id: number|null, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startDetails());
  
        await axios.get('vehicle/maintenance-fuel/' + id).then(result => {
            let data = result.data;
            
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishDetails(data));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishDetails(null));
        });
    },

    callCreateApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startCreate());
  
        await axios.post('vehicle/maintenance-fuel', params).then(result => {
            let data = result.data;
            
            successAPI(data);

            let obj = (data && data.data) ? data.data : null;
            callback(true, obj);
            dispatch(actions.finishCreate(obj));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishCreate(null));
        });
    },

    callUpdateApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startUpdate());
  
        await axios.put('vehicle/maintenance-fuel', params).then(result => {
            let data = result.data;
            
            successAPI(data);

            let obj = (data && data.data) ? data.data : null;
            callback(true, obj);
            dispatch(actions.finishUpdate(obj));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishUpdate(null));
        });
    },

    callDeleteApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startDelete());
  
        await axios.delete('vehicle/maintenance-fuel', { data: params }).then(result => {
            let data = result.data;
                
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishDelete(data));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishDelete(null));
        });
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();