/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-loop-func */
import React from 'react';
import { createContext, useContext } from 'react';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from 'lodash';

import axios from '../../utils/axios';
import { ReadAPIParams, successAPI, errorAPI } from '../../utils/utils';
import Yup from '../../utils/yup';
// import {  } from 'src/utils/enums';


export const fields = {
  driverName: {
    id: 'driverName',
    label: 'Driver name',
    placeholder: 'Enter driver name',
  },
  phoneNumber: {
    id: 'phoneNumber',
    label: 'Phone number',
    placeholder: 'Enter phone number',
  },
  isAssignJobToPhone: {
    id: 'isAssignJobToPhone',
    label: 'Is assign job to phone',
    placeholder: '',
  },
  username: {
    id: 'username',
    label: 'Username',
    placeholder: 'Enter username',
  },
  password: {
    id: 'password',
    label: 'Password',
    placeholder: 'Enter password',
    info: 'The password must contain at least six letters or numbers'
  },
  vehicleId: {
    id: 'vehicleId',
    label: 'Vehicle name',
    placeholder: 'Enter vehicle  name',
  },
};

export const formSchema = (id: number|null= null) => {
  return Yup.object().shape({
    driverName: Yup.string().nullable().required().label(fields.driverName.label),
    phoneNumber: Yup.string().nullable().label(fields.phoneNumber.label),
    vehicleId: Yup.number().nullable().label(fields.vehicleId.label),

    isAssignJobToPhone: Yup.bool().oneOf([true, false]),
    username: Yup.string().when(['isAssignJobToPhone'], ([isAssignJobToPhone]) => {
      if(isAssignJobToPhone){
        return Yup.string().nullable().required().label(fields.username.label);
      } else {
        return Yup.string().nullable().label(fields.username.label);
      }
    }),
    password: Yup.string().when(['isAssignJobToPhone'], ([isAssignJobToPhone]) => {
      if(!id && isAssignJobToPhone){
        return Yup.string().nullable().required().min(6).matches(/^[a-zA-Z0-9]{6,}$/g, fields.password.info).label(fields.password.label);
      } else {
        return Yup.string().min(6).matches(/^[a-zA-Z0-9]{6,}$/g, fields.password.info).label(fields.password.label);
      }
    }),
  })
}


let formikContext: any = null;
export const FormikContext = createContext<any>(null);
export const useFormikContext = () => {
    formikContext = useContext(FormikContext);
    if (!formikContext) {
      throw new Error('useFormikContext must be used within a FormikProvider');
    }
    return formikContext;
};


export const prepareForm = (values: any = null, defValues: any = null) => {
  let form = _.cloneDeep(values);
  let data = _.cloneDeep(defValues);
  
  if(data && form){
    let driverId = (form.driverId) ? form.driverId : null;

    let isAssignJobToPhone = ((form.isAssignJobToPhone == false) || (form.isAssignJobToPhone == true)) ? form.isAssignJobToPhone : false;

    let driverName = (form.driverName && form.driverName !== '') ? form.driverName : '';
    let phoneNumber = (form.phoneNumber && form.phoneNumber !== '') ? form.phoneNumber : '';
    let username = (form.username && form.username !== '') ? form.username : '';
    let password = (form.password && form.password !== '') ? form.password : '';
    
    let vehicle = (form.vehicle) ? form.vehicle : null;
    let vehicleId = (vehicle && vehicle.vehicleId && vehicle.vehicleId !== '') ? vehicle.vehicleId : initialValues.vehicleId;
    let vehicleName = (vehicle && vehicle.vehicleName && vehicle.vehicleName !== '') ? vehicle.vehicleName : initialValues.vehicleName;

    data['driverId'] = driverId;
    data['driverName'] = driverName;
    data['phoneNumber'] = phoneNumber;
    data['isAssignJobToPhone'] = isAssignJobToPhone;
    data['username'] = username;
    data['password'] = password;
    data['vehicleId'] = vehicleId;
    data['vehicleName'] = vehicleName;
  }
  
  return data;
};
export const prepareData = (values: any = null) => {
  let data: any = {};

  if(values){
    if(values.driverId){
      data['driverId'] = values.driverId;
    }
    
    data['driverName'] = values.driverName;
    data['phoneNumber'] = values.phoneNumber;
    data['isAssignJobToPhone'] = values.isAssignJobToPhone;
    data['vehicleId'] = values.vehicleId;

    if(values.isAssignJobToPhone){
      data['username'] = values.username;
      data['password'] = values.password;
    }

  }

  return data;
};


export interface initialValuesStruct {
  driverId: number|null,
  driverName: string,
  phoneNumber: string,
  isAssignJobToPhone: boolean,
  username: string,
  password: string,
  vehicleId: number|null,
  vehicleName: string,
};
export const initialValues: initialValuesStruct = {
  driverId: null,
  driverName: '',
  phoneNumber: '',
  isAssignJobToPhone: true, 
  username: '',
  password: '',
  vehicleId: null,
  vehicleName: '',
};


interface InitState {
  isLoading: boolean,
  show: boolean,
  id: any|null,
  details: any,

  isLoadingDetails: boolean,
  isLoadingCreateUpdateDelete: boolean,
}

function NewReducer() {
  const name = 'driversSlice';


  const initialState: InitState = {
    isLoading: false,
    show: false,
    id: null,
    details: initialValues,

    isLoadingDetails: false,
    isLoadingCreateUpdateDelete: false,
  };


  const reducers = {
    resetSlice: () => {
      return initialState;
    },
    setLoading: (state: InitState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setId: (state: InitState, action: PayloadAction<any>) => {
      state.id = action.payload;
    },
    setShow: (state: InitState, action: PayloadAction<{ show: boolean, id: number|null}>) => {
      state.id = action.payload.id;
      state.show = action.payload.show;
    },
    setValues: (state: InitState, action: PayloadAction<any>) => {
      state.details = action.payload;
    },

    startRead: (state: InitState) => {
      state.isLoading = true;
      // state.list = [];
    },
    finishRead: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
      let data = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];
      // state.list = data;
    },

    startDetails: (state: InitState) => {
      state.isLoadingDetails = true;
    },
    finishDetails: (state: InitState, action: PayloadAction<any>) => {
      state.details = action.payload;
      state.isLoadingDetails = false;
    },

    startCreate: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishCreate: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },

    startUpdate: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishUpdate: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },

    startDelete: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishDelete: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },
  };


  const apis = {
    callReadApi: (params: ReadAPIParams, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startRead());
  
        await axios.get('driver', { params: params }).then(result => {
            let data = result.data;
            
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishRead(data));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishRead(null));
        });
    },

    callDetailsApi: (id: number|null, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startDetails());
  
        await axios.get('driver/' + id).then(result => {
            let data = result.data;
            
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishDetails(data));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishDetails(null));
        });
    },

    callCreateApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startCreate());
  
        await axios.post('driver', params).then(result => {
            let data = result.data;
            
            successAPI(data);

            let obj = (data && data.data) ? data.data : null;
            callback(true, obj);
            dispatch(actions.finishCreate(obj));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishCreate(null));
        });
    },

    callUpdateApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startUpdate());
  
        await axios.put('driver', params).then(result => {
            let data = result.data;
            
            successAPI(data);

            let obj = (data && data.data) ? data.data : null;
            callback(true, obj);
            dispatch(actions.finishUpdate(obj));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishUpdate(null));
        });
    },

    callDeleteApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startDelete());
  
        await axios.delete('driver', { data: params }).then(result => {
            let data = result.data;
                
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishDelete(data));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishDelete(null));
        });
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();