import { createContext, useContext } from 'react';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from 'lodash';
import moment from 'moment';

import axios from '../../utils/axios';
import { successAPI, errorAPI } from '../../utils/utils';
// import {  } from '../../utils/enums';


export const DownloadDocument = {
  None: 0,
  Excel: 1,
  Pdf: 2,
}
export const DownloadDateRange = {
  None: 0,
  All: 1,
  Today: 2,
  CurrentMonth: 3,
  Last7Days: 4,
  LastMonth: 5,
  Custom: 6,
}

export const defaultRange = {
  startDate: moment().startOf('day').toDate(),
  endDate: moment().endOf('day').toDate(),
  key: 'selection'
};

export const today = {
  startDate: moment(),
  endDate: moment(),
};
export const currentMonth = {
  startDate: moment().startOf('month'),
  endDate: moment().endOf('month'),
};
export const last7Days = {
  startDate: moment().subtract(7, 'd'),
  endDate: moment(),
};
export const lastMonth = {
  startDate: moment().subtract(1, 'month').startOf('month'),
  endDate: moment().subtract(1, 'month').endOf('month'),
};


interface InitState {
  show: boolean,
  statusFilter: string|null,
  fileName: string,

  document: number,
  dateRange: number,

  isLoading: boolean,
  details: any,
}


function NewReducer() {
  const name = 'downloadSlice';


  const initialState: InitState = {
    show: false,
    statusFilter: null,
    fileName: '',

    document: DownloadDocument.Excel,
    dateRange: DownloadDateRange.Today,

    isLoading: false,
    details: null,
  };


  const reducers = {
    setShow: (state: InitState, action: PayloadAction<{ show: boolean, statusFilter: string|null, fileName: string }>) => {
      state.isLoading = false;
      state.details = null;
      state.document = DownloadDocument.Excel;
      state.dateRange = DownloadDateRange.Today;
      state.fileName = action.payload.fileName;
      state.statusFilter = action.payload.statusFilter;
      state.show = action.payload.show;
    },
    
    setDocument: (state: InitState, action: PayloadAction<number>) => {
      state.document = action.payload;
    },
    setDateRange: (state: InitState, action: PayloadAction<number>) => {
      state.dateRange = action.payload;
    },

    startDownload: (state: InitState) => {
      state.isLoading = true;
      state.details = null;
    },
    finishDownload: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.details = action.payload;
    },
  };


  const apis = {
    callDownloadApi: (method: string, path: string, params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startDownload());

      if(method === 'post'){
        await axios.post(path, params).then(result => {
          let data = result.data;
          
          successAPI(data);
  
          callback(true, data);
          dispatch(actions.finishDownload(data));
        }).catch(error => {
          errorAPI(error);
          
          callback(false, null);
          dispatch(actions.finishDownload(null));
        });

      } else {
        await axios.get(path, { params: params }).then(result => {
          let data = result.data;
          
          successAPI(data);
  
          callback(true, data);
          dispatch(actions.finishDownload(data));
        }).catch(error => {
          errorAPI(error);
          
          callback(false, null);
          dispatch(actions.finishDownload(null));
        });
      }
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();