/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-loop-func */
import React from 'react';
import { createContext, useContext } from 'react';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from 'lodash';
import moment from 'moment';

import axios from '../../../utils/axios';
import { ReadAPIParams, successAPI, errorAPI, dateFormat, timeFormat, isNumeric, concatDateTime, isBase64, getBase64 } from '../../../utils/utils';
import Yup from '../../../utils/yup';
import { PhotoScanType, VehicleChecklistOptionType } from 'src/utils/enums';


export const fields = {
  date: {
    id: 'date',
    label: 'Date',
    placeholder: 'Enter date',
  },
  time: {
    id: 'time',
    label: 'Time',
    placeholder: 'Enter time',
  },
  vehicleId: {
    id: 'vehicleId',
    label: 'Vehicle',
    placeholder: 'Choose vehicle',
  },
  odometer: {
    id: 'odometer',
    label: 'Mileage',
    placeholder: 'Enter mileage',
  },
  remarks: {
    id: 'remarks',
    label: 'Remarks',
    placeholder: 'Enter remarks',
    rows: 3,
    maxWords: 100,
  },
  photos: {
    id: 'photos',
    label: 'Photo',
    placeholder: '',
    required: 'Photo is a required field',
  },
  GPSOdometer: {
    id: 'GPSOdometer',
    label: 'GPS odometer',
    placeholder: '',
  },
  driver: {
    id: 'driver',
    label: 'Driver',
    placeholder: '',
  },
  type: {
    id: 'type',
    label: 'Checklist type',
    placeholder: '',
  },

  details: {
    id: 'details',
    label: 'Checklist',
    placeholder: '',
  },
  vehicleMaintenanceChecklistOptionId: {
    id: 'vehicleMaintenanceChecklistOptionId',
    label: 'Item',
    placeholder: '',
  },
};

export const formSchema = (id: number|null= null) => {
  return Yup.object().shape({
    date: Yup.string().nullable().required().maxDate().label(fields.date.label),
    time: Yup.string().nullable().required().label(fields.time.label),
    vehicleId: Yup.number().nullable().required().label(fields.vehicleId.label),
    odometer: Yup.string().nullable().required().label(fields.odometer.label),

    preCheck: Yup.array().when(['type'], ([type]) => {
      if(type === VehicleChecklistOptionType.PostCheck){
        return Yup.array().of(Yup.object({
          vehicleMaintenanceChecklistOptionId: Yup.number().nullable().label(fields.vehicleMaintenanceChecklistOptionId.label),
          photos: Yup.array().of(Yup.object()).label(fields.photos.label),
          remarks: Yup.string().nullable().label(fields.remarks.label),
        }))
      } else {
        return Yup.array().of(Yup.object({
          vehicleMaintenanceChecklistOptionId: Yup.number().nullable().positive().min(0).required().label(fields.vehicleMaintenanceChecklistOptionId.label),
          photos: Yup.array().when(['condition'], ([condition]) => {
            if(!id){
              return Yup.array().of(Yup.object()).label(fields.photos.label);
            } else {
              if(condition === false){
                return Yup.array().of(Yup.object()).min(1, fields.photos.required).label(fields.photos.label);
              } else {
                return Yup.array().of(Yup.object()).label(fields.photos.label);
              }
            }
          }),
          remarks: Yup.string().nullable().maxWords(fields.remarks.maxWords).label(fields.remarks.label),
        }))
      }
    }),

    postCheck: Yup.array().when(['type'], ([type]) => {
      if(type === VehicleChecklistOptionType.PreCheck){
        return Yup.array().of(Yup.object({
          vehicleMaintenanceChecklistOptionId: Yup.number().nullable().label(fields.vehicleMaintenanceChecklistOptionId.label),
          photos: Yup.array().of(Yup.object()).label(fields.photos.label),
          remarks: Yup.string().nullable().label(fields.remarks.label),
        }))
      } else {
        return Yup.array().of(Yup.object({
          vehicleMaintenanceChecklistOptionId: Yup.number().nullable().positive().min(0).required().label(fields.vehicleMaintenanceChecklistOptionId.label),
          photos: Yup.array().when(['condition'], ([condition]) => {
            if(!id){
              return Yup.array().of(Yup.object()).label(fields.photos.label);
            } else {
              if(condition === false){
                return Yup.array().of(Yup.object()).min(1, fields.photos.required).label(fields.photos.label);
              } else {
                return Yup.array().of(Yup.object()).label(fields.photos.label);
              }
            }
          }),
          remarks: Yup.string().nullable().maxWords(fields.remarks.maxWords).label(fields.remarks.label),
        }))
      }
    }),
  })
}


let formikContext: any = null;
export const FormikContext = createContext<any>(null);
export const useFormikContext = () => {
    formikContext = useContext(FormikContext);
    if (!formikContext) {
      throw new Error('useFormikContext must be used within a FormikProvider');
    }
    return formikContext;
};


export const prepareForm = (options: any = [], values: any = null, defValues: any = null) => {
  let form = _.cloneDeep(values);
  let data = _.cloneDeep(defValues);

  let preCheck: any = [];
  let postCheck: any = [];
  if(options && options.length > 0){
    let preCheckItems = options.filter((x: any) => x.type === VehicleChecklistOptionType.PreCheck);
    preCheck = (preCheckItems && preCheckItems.length > 0)
      ?
      preCheckItems.map((item: any) => {
        let vehicleMaintenanceChecklistOptionId = (item && item.vehicleMaintenanceChecklistOptionId && item.vehicleMaintenanceChecklistOptionId !== '') ? item.vehicleMaintenanceChecklistOptionId : '';
        let name = (item && item.name && item.name !== '') ? item.name : '';
        let condition = null;
        let photos: any = [];
        let remarks: any = '';

        let details: DetailsStruct = {
          vehicleMaintenanceChecklistOptionId: vehicleMaintenanceChecklistOptionId,
          name: name,
          condition: condition,
          photos: photos,
          remarks: remarks,
        };
        return details
      })
    :
    [];

    let postCheckItems = options.filter((x: any) => x.type === VehicleChecklistOptionType.PostCheck);
    postCheck = (postCheckItems && postCheckItems.length > 0)
      ?
      postCheckItems.map((item: any) => {
        let vehicleMaintenanceChecklistOptionId = (item && item.vehicleMaintenanceChecklistOptionId && item.vehicleMaintenanceChecklistOptionId !== '') ? item.vehicleMaintenanceChecklistOptionId : '';
        let name = (item && item.name && item.name !== '') ? item.name : '';
        let condition = null;
        let photos: any = [];
        let remarks: any = '';

        let details: DetailsStruct = {
          vehicleMaintenanceChecklistOptionId: vehicleMaintenanceChecklistOptionId,
          name: name,
          condition: condition,
          photos: photos,
          remarks: remarks,
        };
        return details
      })
    :
    [];
  }
  data['preCheck'] = preCheck;
  data['postCheck'] = postCheck;


  if(data && form){
    let vehicleMaintenanceChecklistId = (form.vehicleMaintenanceChecklistId) ? form.vehicleMaintenanceChecklistId : null;

    let type = (isNumeric(form.type)) ? form.type : initialValues.type;
    let odometer = (isNumeric(form.odometer)) ? form.odometer : initialValues.odometer;

    let vehicle = (form.vehicle) ? form.vehicle : null;
    let vehicleId = (vehicle && vehicle.vehicleId && vehicle.vehicleId !== '') ? vehicle.vehicleId : initialValues.vehicleId;
    let vehicleName = (vehicle && vehicle.vehicleName && vehicle.vehicleName !== '') ? vehicle.vehicleName : initialValues.vehicleName;
    
    let maintenanceDateTime = (form.maintenanceDateTime && form.maintenanceDateTime !== '') ? form.maintenanceDateTime : '';
    let date = (form.maintenanceDateTime && form.maintenanceDateTime !== '') ? moment(maintenanceDateTime).format(dateFormat()) : initialValues.date;
    let time = (form.maintenanceDateTime && form.maintenanceDateTime !== '') ? moment(maintenanceDateTime).format(timeFormat()) : initialValues.time;


    if(form.details && form.details.length > 0){
      if(type === VehicleChecklistOptionType.PostCheck){
        form.details.forEach((item: any) => {
          let condition = (item && ((item.condition === false) || (item.condition === true))) ? item.condition : null;
          let remarks = (item && item.remarks && item.remarks !== '') ? item.remarks : '';
          let photoUrl = (item && item.photoUrl && item.photoUrl !== '') ? item.photoUrl : null;

          let itemIndex = data['postCheck'].findIndex((x: any) => x.vehicleMaintenanceChecklistOptionId === item.vehicleMaintenanceChecklistOptionId);
          if(itemIndex > -1){
            data['postCheck'][itemIndex]['condition'] = condition;
            data['postCheck'][itemIndex]['remarks'] = remarks;
            data['postCheck'][itemIndex]['photoUrl'] = photoUrl;

            if(condition != null && photoUrl != null){
              data['postCheck'][itemIndex]['photos'] = [{
                uid: item.vehicleMaintenanceChecklistOptionId,
                name: 'name_' + item.vehicleMaintenanceChecklistOptionId,
                status: 'done',
                url: photoUrl,
                thumbUrl: photoUrl,
                preview: photoUrl,
                data: item,
              }]
            }

          } else {
            let option = (item && item.option) ? item.option : null;
            let name = (option && option.name && option.name !== '') ? option.name : '';
  
            let newItem: any = {
              vehicleMaintenanceChecklistOptionId: item.vehicleMaintenanceChecklistOptionId,
              name: name,
              condition: null,
            };
            data['postCheck'].push(newItem);
          }
        });

      } else {

        form.details.forEach((item: any) => {
          let condition = (item && ((item.condition === false) || (item.condition === true))) ? item.condition : null;
          let remarks = (item && item.remarks && item.remarks !== '') ? item.remarks : '';
          let photoUrl = (item && item.photoUrl && item.photoUrl !== '') ? item.photoUrl : null;

          let itemIndex = data['preCheck'].findIndex((x: any) => x.vehicleMaintenanceChecklistOptionId === item.vehicleMaintenanceChecklistOptionId);
          if(itemIndex > -1){
            data['preCheck'][itemIndex]['condition'] = condition;
            data['preCheck'][itemIndex]['remarks'] = remarks;
            data['preCheck'][itemIndex]['photoUrl'] = photoUrl;
            
            if(condition != null && photoUrl != null){
              data['preCheck'][itemIndex]['photos'] = [{
                uid: item.vehicleMaintenanceChecklistOptionId,
                name: 'name_' + item.vehicleMaintenanceChecklistOptionId,
                status: 'done',
                url: photoUrl,
                thumbUrl: photoUrl,
                preview: photoUrl,
                data: item,
              }]
            }

          } else {
            let option = (item && item.option) ? item.option : null;
            let name = (option && option.name && option.name !== '') ? option.name : '';
  
            let newItem: any = {
              vehicleMaintenanceChecklistOptionId: item.vehicleMaintenanceChecklistOptionId,
              name: name,
              condition: null,
            };
            data['preCheck'].push(newItem);
          }
        });
      }
    }


    data['vehicleMaintenanceChecklistId'] = vehicleMaintenanceChecklistId;

    data['type'] = type;
    data['odometer'] = odometer;

    data['vehicleId'] = vehicleId;
    data['vehicleName'] = vehicleName;
    
    data['date'] = date;
    data['time'] = time;
  }
  
  return data;
};
export const prepareData = (values: any = null) => {
  let data: any = {};

  if(values){
    if(values.vehicleMaintenanceChecklistId){
      data['vehicleMaintenanceChecklistId'] = values.vehicleMaintenanceChecklistId;
    }
    
    data['type'] = values.type;
    data['odometer'] = values.odometer;
    data['vehicleId'] = values.vehicleId;
    data['maintenanceDateTime'] = concatDateTime(values.date, values.time);


    let checklist: any = [];
    if(values.type === VehicleChecklistOptionType.PostCheck){
      checklist = (values.postCheck && values.postCheck.length > 0) ? values.postCheck: [];
    } else {
      checklist = (values.preCheck && values.preCheck.length > 0) ? values.preCheck: [];
    }


    let details: Array<any> = [];
    if(checklist && checklist.length > 0){
      checklist.forEach((item: any, i: number) => {
        let vehicleMaintenanceChecklistOptionId = (item && item.vehicleMaintenanceChecklistOptionId && item.vehicleMaintenanceChecklistOptionId !== '') ? item.vehicleMaintenanceChecklistOptionId : '';
        let condition = (item && ((item.condition === false) || (item.condition === true))) ? item.condition : null;
        let remarks = (item && item.remarks && item.remarks !== '') ? item.remarks : '';
        
        
        let photo: any = null;
        if(item.photos && item.photos.length > 0){
          let file = item.photos[0];

          if(file){
            if(isBase64(file?.url)){
              let fileData = getBase64(file);
      
              photo = {
                base64Image: fileData?.base64,
                fileName: file.name,
                photoScanType: PhotoScanType.Gallery,
              }
            } else {
              photo = { photoUrl: file?.url };
            }
          }
        }

        
        let detailsItm = null;
        let itm = {
          vehicleMaintenanceChecklistOptionId: vehicleMaintenanceChecklistOptionId,
          condition: condition,
        }

        if(condition === false){
          detailsItm = {...itm, ...photo, remarks: remarks};
        } else if(condition === true){
          detailsItm = itm;
        }

        if(detailsItm != null){
          details.push(detailsItm);
        }
      });
    }
    data['details'] = details;
  }

  return data;
};


interface DetailsStruct {
  vehicleMaintenanceChecklistOptionId: any|null,
  name: string|null,
  condition: boolean|null,
  photos: Array<any>,
  remarks: string,
};
export const initDetails: DetailsStruct = {
  vehicleMaintenanceChecklistOptionId: null,
  name: '',
  condition: null,
  photos: [],
  remarks: '',
};


export interface initialValuesStruct {
  date: string,
  time: string,
  vehicleId: number|null,
  vehicleName: string,
  odometer: string,
  type: number,
  
  details: Array<DetailsStruct>,
};
export const initialValues: initialValuesStruct = {
  date: moment().format(dateFormat()),
  time: moment().format(timeFormat()),
  vehicleId: null,
  vehicleName: '',
  odometer: '',
  type: VehicleChecklistOptionType.PreCheck,

  details: [initDetails],
};


interface InitState {
  isLoading: boolean,
  show: boolean,
  id: any|null,
  details: any,
  options: Array<any>,

  isLoadingDetails: boolean,
  isLoadingCreateUpdateDelete: boolean,
}

function NewReducer() {
  const name = 'checklistSlice';


  const initialState: InitState = {
    isLoading: false,
    show: false,
    id: null,
    details: initialValues,
    options: [],

    isLoadingDetails: false,
    isLoadingCreateUpdateDelete: false,
  };


  const reducers = {
    resetSlice: () => {
      return initialState;
    },
    setLoading: (state: InitState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setId: (state: InitState, action: PayloadAction<any>) => {
      state.id = action.payload;
    },
    setShow: (state: InitState, action: PayloadAction<{ show: boolean, id: number|null}>) => {
      state.id = action.payload.id;
      state.show = action.payload.show;
    },
    setValues: (state: InitState, action: PayloadAction<any>) => {
      state.details = action.payload;
    },

    startRead: (state: InitState) => {
      state.isLoading = true;
      // state.list = [];
    },
    finishRead: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
      let data = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];
      // state.list = data;
    },

    startDetails: (state: InitState) => {
      state.isLoadingDetails = true;
    },
    finishDetails: (state: InitState, action: PayloadAction<any>) => {
      state.details = action.payload;
      state.isLoadingDetails = false;
    },

    startCreate: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishCreate: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },

    startUpdate: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishUpdate: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },

    startDelete: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishDelete: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },

    startReadOptions: (state: InitState) => {
      state.isLoading = true;
      state.options = [];
    },
    finishReadOptions: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
      let data = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];
      state.options = data;
    },
  };


  const apis = {
    callReadApi: (params: ReadAPIParams, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startRead());
  
        await axios.get('vehicle/maintenance-checklist', { params: params }).then(result => {
            let data = result.data;
            
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishRead(data));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishRead(null));
        });
    },

    callDetailsApi: (id: number|null, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startDetails());
  
        await axios.get('vehicle/maintenance-checklist/' + id).then(result => {
            let data = result.data;
            
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishDetails(data));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishDetails(null));
        });
    },

    callCreateApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startCreate());
  
        await axios.post('vehicle/maintenance-checklist', params).then(result => {
            let data = result.data;
            
            successAPI(data);

            let obj = (data && data.data) ? data.data : null;
            callback(true, obj);
            dispatch(actions.finishCreate(obj));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishCreate(null));
        });
    },

    callUpdateApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startUpdate());
  
        await axios.put('vehicle/maintenance-checklist', params).then(result => {
            let data = result.data;
            
            successAPI(data);

            let obj = (data && data.data) ? data.data : null;
            callback(true, obj);
            dispatch(actions.finishUpdate(obj));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishUpdate(null));
        });
    },

    callDeleteApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startDelete());
  
        await axios.delete('vehicle/maintenance-checklist', { data: params }).then(result => {
            let data = result.data;
                
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishDelete(data));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishDelete(null));
        });
    },

    callReadOptionsApi: (params: ReadAPIParams, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startReadOptions());
  
        await axios.get('vehicle/checklist/option', { params: params }).then(result => {
            let data = result.data;
            let list = (data && data.data && data.data.length > 0) ? data.data : [];
            
            successAPI(data);

            callback(true, list);
            dispatch(actions.finishReadOptions(data));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishReadOptions(null));
        });
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();