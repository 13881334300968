import { createSlice, PayloadAction } from "@reduxjs/toolkit";


interface InitState {
  show: boolean,
  items: Array<string>,
  index: number,
}


function NewReducer() {
  const name = 'imagePreviewSlice';


  const initialState: InitState = {
    show: false,
    items: [],
    index: 0,
  };


  const reducers = {
    setShow: (state: InitState, action: PayloadAction<{ items: Array<string>, index: number }>) => {
      state.items = action.payload.items;
      state.index = action.payload.index;
      state.show = true;
    },
    setHide: (state: InitState) => {
      state.items = [];
      state.index = 0;
      state.show = false;
    },
    setVisibility: (state: InitState, action: PayloadAction<boolean>) => {
      state.show = action.payload;
    },
    setIndex: (state: InitState, action: PayloadAction<number>) => {
      state.index = action.payload;
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
  };
}


export default NewReducer();