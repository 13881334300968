/* eslint-disable import/no-unresolved */
import { lazy, Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { PATH_AFTER_LOGIN } from '../utils/enums';

import AuthGuard from '../auth/auth-guard.js';
import LoadingScreen from '../components/loading/LoadingScreen.tsx';
import PrivateLayout from '../layouts/PrivateLayout.tsx';

// ----------------------------------------------------------------------

// MENU
const DesignSystemPage = lazy(() => import('../pages/designSystem/DesignSystem.tsx'));
const VehiclesPage = lazy(() => import('../pages/vehicles/Page.tsx'));
const DriversPage = lazy(() => import('../pages/drivers/Page.tsx'));
const VehicleMaintenancePage = lazy(() => import('../pages/vehicleMaintenance/Page.tsx'));

// ----------------------------------------------------------------------

export const privateRoutes = [
  {
    path: '/',
    element: <Navigate to={PATH_AFTER_LOGIN} replace />,
  },

  {
    path: 'design-system',
    element: (
      <AuthGuard>
        <PrivateLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </PrivateLayout>
      </AuthGuard>
    ),
    children: [
      { element: <DesignSystemPage />, index: true },
      { path: 'design-system', element: <DesignSystemPage /> },
    ],
  },

  {
    path: 'drivers',
    element: (
      <AuthGuard>
        <PrivateLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </PrivateLayout>
      </AuthGuard>
    ),
    children: [
      { element: <DriversPage />, index: true },
      { path: 'drivers', element: <DriversPage /> },
    ],
  },

  {
    path: 'vehicles',
    element: (
      <AuthGuard>
        <PrivateLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </PrivateLayout>
      </AuthGuard>
    ),
    children: [
      { element: <VehiclesPage />, index: true },
      { path: 'vehicles', element: <VehiclesPage /> },
    ],
  },

  {
    path: 'vehicle-maintenance',
    element: (
      <AuthGuard>
        <PrivateLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </PrivateLayout>
      </AuthGuard>
    ),
    children: [
      { element: <VehicleMaintenancePage />, index: true },
      { path: 'vehicle-maintenance', element: <VehicleMaintenancePage /> },
    ],
  },

];
