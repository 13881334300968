import React from 'react';
import styled from 'styled-components';
import { up, down } from 'styled-breakpoints';

import { Tooltip } from 'antd';

import { useRouter } from '../auth/auth-guard.js';

import { MAIN_BREAKPOINT } from '../utils/enums';

import { ReactComponent as TruckIcon } from "../assets/icons/truck.svg";
import { ReactComponent as UserIcon } from "../assets/icons/user.svg";
import { ReactComponent as ToolIcon } from "../assets/icons/tool.svg";

// ----------------------------------------------------------------------

const StyledMenu = styled.div`
  ${up('sm')} {
    width: 80px;
    border-right-width: 1px;
    border-right-style: solid;
    border-color: var(--bs-gray-100);
  }

  ${down(MAIN_BREAKPOINT)} {
    width: auto;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-color: var(--bs-gray-100);

    > div {
      display: inline-block;
    }
  }

  .navigation {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    position: relative;
    background-color: var(--basewhite);

    ${up('sm')} {
      display: flex;
      padding: 12px 16px 0px;
    }

    ${down(MAIN_BREAKPOINT)} {
      display: inline-block;
      padding: 12px 16px;
    }

    svg {
      position: relative;
      width: 20px;
      height: 20px;
    }

    .img-wrapper {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      padding: 12px;
      position: relative;
      flex: 0 0 auto;
      background-color: var(--basewhite);
      border-radius: 6px;
  
      path {
        stroke: var(--bs-gray-600);
      }
  
      &:hover {
        background-color: var(--bs-blue-focus1);

        path {
          stroke: var(--bs-blue-500);
        }
      }

      &.active {
        background-color: var(--bs-blue-focus1);

        path {
          stroke: var(--bs-blue-500);
        }
      }
    }
  }
`;

// ----------------------------------------------------------------------

const Menu = () => {
  const router = useRouter();


  return <StyledMenu>
    <div className="navigation">
      <Tooltip placement={'right'} title={'Vehicles'}>
        <a href={'/vehicles'}
          className={`img-wrapper ${(window.location.pathname === '/vehicles') ? 'active' : ''}`}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();

            router.push('/vehicles');
          }}
        >
          <TruckIcon />
        </a>
      </Tooltip>
      <Tooltip placement={'right'} title={'Drivers'}>
        <a href={'/drivers'}
          className={`img-wrapper ${(window.location.pathname === '/drivers') ? 'active' : ''}`}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();

            router.push('/drivers');
          }}
        >
          <UserIcon />
        </a>
      </Tooltip>
      <Tooltip placement={'right'} title={'Vehicle Maintenance'}>
        <a href={'/vehicle-maintenance'}
          className={`img-wrapper ${(window.location.pathname === '/vehicle-maintenance') ? 'active' : ''}`}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();

            router.push('/vehicle-maintenance');
          }}
        >
          <ToolIcon />
        </a>
      </Tooltip>
    </div>
  </StyledMenu>
}

export default Menu;