export const LOGIN_PATH = '/auth/login';
export const PATH_AFTER_LOGIN = 'vehicles';
export const MAIN_BREAKPOINT = 'md';

export const STORAGE_KEY = 'accessToken';
export const STORAGE_USER = 'accessUser';
export const STORAGE_USER_ACCOUNT = 'accessUserAccount';
export const STORAGE_EXPIRES_AT = 'tokenExpiresAt';

export const Permissions = {
  All: 0,
  Dashboard: 1,
}

export const GPSServer = {
  GPSGate: 0,
}

export const CostType = {
  OtherCost: 0,
  FixedCost: 1,
}

export const RADIUS = 100;

export const MaintenanceStoreType = {
  Fuel: 0,
  Tire: 1,
}

export const VehicleChecklistOptionType = {
  PreCheck: 0,
  PostCheck: 1,
}

export const PhotoScanType = {
  Camera: 0,
  Gallery: 1,
}