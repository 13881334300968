import Router from './routes';
import { AuthProvider } from './auth/context/auth-provider.tsx';
import { AuthConsumer } from './auth/context/auth-consumer.js';

import { Provider } from 'react-redux';
import { store } from './utils/store.tsx';

import ConfirmModal from './components/modal/ConfirmModal';


function App() {
  return <Provider store={store}>
    <AuthProvider>
      <AuthConsumer>
        <Router />
      </AuthConsumer>
    </AuthProvider>
    
    <ConfirmModal />
  </Provider>
}

export default App;
