/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-loop-func */
import React from 'react';
import { createContext, useContext } from 'react';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from 'lodash';
import moment from 'moment';

import axios from '../../../utils/axios';
import { ReadAPIParams, successAPI, errorAPI, dateFormat, timeFormat, isNumeric, concatDateTime, isBase64, getBase64 } from '../../../utils/utils';
import Yup from '../../../utils/yup';
import { PhotoScanType } from 'src/utils/enums';


export const fields = {
  date: {
    id: 'date',
    label: 'Date',
    placeholder: 'Enter date',
  },
  time: {
    id: 'time',
    label: 'Time',
    placeholder: 'Enter time',
  },
  maintenanceStoreId: {
    id: 'maintenanceStoreId',
    label: 'Store',
    placeholder: 'Choose store',
  },
  vehicleId: {
    id: 'vehicleId',
    label: 'Vehicle',
    placeholder: 'Choose vehicle',
  },
  odometer: {
    id: 'odometer',
    label: 'Mileage',
    placeholder: 'Enter mileage',
  },
  remarks: {
    id: 'remarks',
    label: 'Remarks',
    placeholder: 'Enter remarks',
    rows: 3,
    maxWords: 100,
  },
  photos: {
    id: 'photos',
    label: 'Photo',
    placeholder: '',
    required: 'Photo is a required field',
  },
  GPSOdometer: {
    id: 'GPSOdometer',
    label: 'GPS odometer',
    placeholder: '',
  },
  driver: {
    id: 'driver',
    label: 'Driver',
    placeholder: '',
  },
  cost: {
    id: 'cost',
    label: 'Cost',
    placeholder: '',
  },

  details: {
    id: 'tireLocations',
    label: 'Tire locations',
    placeholder: '',
  },
  vehicleTireLocationId: {
    id: 'vehicleTireLocationId',
    label: 'Tire location',
    placeholder: 'Choose tire location',
  },
  number: {
    id: 'number',
    label: 'Qty',
    placeholder: 'Enter qty',
  },
};

export const formSchema = (id: number|null= null) => {
  return Yup.object().shape({
    date: Yup.string().nullable().required().maxDate().label(fields.date.label),
    time: Yup.string().nullable().required().label(fields.time.label),
    maintenanceStoreId: Yup.number().nullable().required().label(fields.maintenanceStoreId.label),
    vehicleId: Yup.number().nullable().required().label(fields.vehicleId.label),
    odometer: Yup.string().nullable().required().label(fields.odometer.label),
    cost: Yup.number().nullable().required().label(fields.cost.label),
    remarks: Yup.string().nullable().maxWords(fields.remarks.maxWords).label(fields.remarks.label),

    photos: Yup.array().when([], () => {
      if(!id){
        return Yup.array().of(Yup.object()).label(fields.photos.label);
      } else {
        return Yup.array().of(Yup.object()).min(1, fields.photos.required).label(fields.photos.label);
      }
    }),

    details: Yup.array().of(Yup.object({
      vehicleTireLocationId: Yup.number().nullable().positive().min(0).required().label(fields.vehicleTireLocationId.label),
      number: Yup.number().nullable().positive().min(1).required().label(fields.number.label),
    }))
    .hasEmpty('vehicleTireLocationId')
    .unique('vehicleTireLocationId'),
  })
}


let formikContext: any = null;
export const FormikContext = createContext<any>(null);
export const useFormikContext = () => {
    formikContext = useContext(FormikContext);
    if (!formikContext) {
      throw new Error('useFormikContext must be used within a FormikProvider');
    }
    return formikContext;
};


export const prepareForm = (values: any = null, defValues: any = null) => {
  let form = _.cloneDeep(values);
  let data = _.cloneDeep(defValues);
  
  if(data && form){
    let vehicleMaintenanceTireId = (form.vehicleMaintenanceTireId) ? form.vehicleMaintenanceTireId : null;

    let odometer = (isNumeric(form.odometer)) ? form.odometer : initialValues.odometer;
    let cost = (isNumeric(form.cost)) ? form.cost : initialValues.cost;
    let remarks = (form.remarks && form.remarks !== '') ? form.remarks : initialValues.remarks;

    let vehicle = (form.vehicle) ? form.vehicle : null;
    let vehicleId = (vehicle && vehicle.vehicleId && vehicle.vehicleId !== '') ? vehicle.vehicleId : initialValues.vehicleId;
    let vehicleName = (vehicle && vehicle.vehicleName && vehicle.vehicleName !== '') ? vehicle.vehicleName : initialValues.vehicleName;
    
    let maintenanceStore = (form.maintenanceStore) ? form.maintenanceStore : null;
    let maintenanceStoreId = (maintenanceStore && maintenanceStore.maintenanceStoreId && maintenanceStore.maintenanceStoreId !== '') ? maintenanceStore.maintenanceStoreId : initialValues.maintenanceStoreId;
    let maintenanceStoreName = (maintenanceStore && maintenanceStore.name && maintenanceStore.name !== '') ? maintenanceStore.name : initialValues.maintenanceStoreName;

    let maintenanceDateTime = (form.maintenanceDateTime && form.maintenanceDateTime !== '') ? form.maintenanceDateTime : '';
    let date = (form.maintenanceDateTime && form.maintenanceDateTime !== '') ? moment(maintenanceDateTime).format(dateFormat()) : initialValues.date;
    let time = (form.maintenanceDateTime && form.maintenanceDateTime !== '') ? moment(maintenanceDateTime).format(timeFormat()) : initialValues.time;

    let photos: any = [];
    if(form.photos && form.photos.length > 0){
      photos = form.photos.map((file: any, i: number) => {
        return {
          uid: file.vehicleMaintenanceFuelPhotoId,
          name: 'name_' + i,
          status: 'done',
          url: file.photoUrl,
          thumbUrl: file.photoUrl,
          preview: file.photoUrl,
          data: file,
        }
      });
    }

    let details = (form.details && form.details.length > 0)
      ?
      form.details.map((item: any) => {
        let tireLocation = (item && item.tireLocation) ? item.tireLocation : null;
        let vehicleTireLocationId = (tireLocation && tireLocation.vehicleTireLocationId && tireLocation.vehicleTireLocationId !== '') ? tireLocation.vehicleTireLocationId : '';
        let name = (tireLocation && tireLocation.name && tireLocation.name !== '') ? tireLocation.name : '';
        
        let number = (item && item.number && item.number !== '') ? item.number : '';
      
        let details: Details = {
          vehicleTireLocationId: vehicleTireLocationId,
          name: name,
          number: number,
        };
        return details
      })
    :
    [initDetails];


    data['vehicleMaintenanceTireId'] = vehicleMaintenanceTireId;

    data['odometer'] = odometer;
    data['cost'] = cost;
    data['remarks'] = remarks;

    data['vehicleId'] = vehicleId;
    data['vehicleName'] = vehicleName;

    data['maintenanceStoreId'] = maintenanceStoreId;
    data['maintenanceStoreName'] = maintenanceStoreName;
    
    data['date'] = date;
    data['time'] = time;

    data['photos'] = photos;

    data['details'] = details;
  }
  
  return data;
};
export const prepareData = (values: any = null) => {
  let data: any = {};

  if(values){
    if(values.vehicleMaintenanceTireId){
      data['vehicleMaintenanceTireId'] = values.vehicleMaintenanceTireId;
    }
    
    data['odometer'] = values.odometer;
    data['cost'] = values.cost;
    data['remarks'] = values.remarks;

    data['vehicleId'] = values.vehicleId;
    data['maintenanceStoreId'] = values.maintenanceStoreId;
    
    data['maintenanceDateTime'] = concatDateTime(values.date, values.time);

    let photos: any = [];
    if(values.photos && values.photos.length > 0){
      photos = values.photos.map((file: any, i: number) => {
        if(isBase64(file?.url)){
          let fileData = getBase64(file);
  
          return {
            base64Image: fileData?.base64,
            fileName: file.name,
            photoScanType: PhotoScanType.Gallery,
          }
        } else {
          return file?.data;
        }
      });
    }
    data['photos'] = photos;

    let details: Array<any> = [];
    if(values.details && values.details.length > 0){
      values.details.forEach((item: any, i: number) => {
        if(
          (values.details.length == 1) &&
          (item.vehicleTireLocationId === null || item.vehicleTireLocationId === undefined || item.vehicleTireLocationId === '') && 
          (item.number === null || item.number === undefined || item.number === '')
        ){
          //nop
        } else {
          let vehicleTireLocationId = (item && item.vehicleTireLocationId && item.vehicleTireLocationId !== '') ? item.vehicleTireLocationId : '';
          let name = (item && item.name && item.name !== '') ? item.name : '';
          let number = (item && item.number && item.number !== '') ? item.number : '';

          details.push({
            vehicleTireLocationId: vehicleTireLocationId,
            name: name,
            number: number,
          })
        }
      });
    }
    data['details'] = details;
  }

  return data;
};


interface Details {
  vehicleTireLocationId: any|null,
  name: string|null,
  number: string|null,
};
export const initDetails: Details = {
  vehicleTireLocationId: null,
  name: '',
  number: '1',
};


export interface initialValuesStruct {
  date: string,
  time: string,
  maintenanceStoreId: any,
  maintenanceStoreName: string,
  vehicleId: number|null,
  vehicleName: string,
  odometer: string,
  cost: string,
  photos: Array<any>,
  remarks: string,
  
  details: Array<Details>,
};
export const initialValues: initialValuesStruct = {
  date: moment().format(dateFormat()),
  time: moment().format(timeFormat()),
  maintenanceStoreId: null,
  maintenanceStoreName: '',
  vehicleId: null,
  vehicleName: '',
  odometer: '',
  cost: '',
  photos: [],
  remarks: '',

  details: [initDetails],
};


interface InitState {
  isLoading: boolean,
  show: boolean,
  id: any|null,
  details: any,

  isLoadingDetails: boolean,
  isLoadingCreateUpdateDelete: boolean,
}

function NewReducer() {
  const name = 'tireSlice';


  const initialState: InitState = {
    isLoading: false,
    show: false,
    id: null,
    details: initialValues,

    isLoadingDetails: false,
    isLoadingCreateUpdateDelete: false,
  };


  const reducers = {
    resetSlice: () => {
      return initialState;
    },
    setLoading: (state: InitState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setId: (state: InitState, action: PayloadAction<any>) => {
      state.id = action.payload;
    },
    setShow: (state: InitState, action: PayloadAction<{ show: boolean, id: number|null}>) => {
      state.id = action.payload.id;
      state.show = action.payload.show;
    },
    setValues: (state: InitState, action: PayloadAction<any>) => {
      state.details = action.payload;
    },

    startRead: (state: InitState) => {
      state.isLoading = true;
      // state.list = [];
    },
    finishRead: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
      let data = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];
      // state.list = data;
    },

    startDetails: (state: InitState) => {
      state.isLoadingDetails = true;
    },
    finishDetails: (state: InitState, action: PayloadAction<any>) => {
      state.details = action.payload;
      state.isLoadingDetails = false;
    },

    startCreate: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishCreate: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },

    startUpdate: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishUpdate: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },

    startDelete: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishDelete: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },
  };


  const apis = {
    callReadApi: (params: ReadAPIParams, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startRead());
  
        await axios.get('vehicle/maintenance-tire', { params: params }).then(result => {
            let data = result.data;
            
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishRead(data));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishRead(null));
        });
    },

    callDetailsApi: (id: number|null, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startDetails());
  
        await axios.get('vehicle/maintenance-tire/' + id).then(result => {
            let data = result.data;
            
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishDetails(data));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishDetails(null));
        });
    },

    callCreateApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startCreate());
  
        await axios.post('vehicle/maintenance-tire', params).then(result => {
            let data = result.data;
            
            successAPI(data);

            let obj = (data && data.data) ? data.data : null;
            callback(true, obj);
            dispatch(actions.finishCreate(obj));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishCreate(null));
        });
    },

    callUpdateApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startUpdate());
  
        await axios.put('vehicle/maintenance-tire', params).then(result => {
            let data = result.data;
            
            successAPI(data);

            let obj = (data && data.data) ? data.data : null;
            callback(true, obj);
            dispatch(actions.finishUpdate(obj));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishUpdate(null));
        });
    },

    callDeleteApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startDelete());
  
        await axios.delete('vehicle/maintenance-tire', { data: params }).then(result => {
            let data = result.data;
                
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishDelete(data));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishDelete(null));
        });
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();