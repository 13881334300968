import React from 'react';
import styled from 'styled-components';

// Auth
import { useAuthContext } from '../auth/use-auth-context.js';
import { useRouter } from '../auth/auth-guard.js';

import {
  Row,
  Col,
  Stack,
  Dropdown,
  Image,
  Form,
  InputGroup,
  Modal,
  Offcanvas,
  Button,
  Badge,
} from 'react-bootstrap';

import { MAIN_BREAKPOINT } from '../utils/enums';
import { getUser, isProduction } from '../utils/utils';

import { CustomToggle, CustomMenu } from '../components/dropdown/Dropdown.jsx';

import { ReactComponent as BellIcon } from "../assets/icons/bell.svg";
import { ReactComponent as SearchIcon } from "../assets/icons/search.svg";

const  DefaultImage = require("../assets/img/default_image.png") as string;

const REACT_APP_VERSION = process.env.REACT_APP_VERSION;

// ----------------------------------------------------------------------

const StyledRow = styled(Row)`
  padding: 10px 16px;
  height: 60px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: #F0F0F1;
`;
const StyledBell = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 105px;
  padding: 7px;
  position: relative;
  background-color: var(--basewhite);
  border-radius: 6px;
  overflow: hidden;
`;
const StyledBellIcon = styled(BellIcon)`
  position: relative;
  width: 16px;
  height: 16px;
`;
const StyledBellLabel = styled.div`
  display: flex;
  flex-direction: column;
  width: 10px;
  height: 10px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 0px 5px;
  position: absolute;
  top: 5px;
  left: 13px;
  background-color: #CB3A31;
  border-radius: 50px;

  .text-wrapper {
    position: relative;
    width: fit-content;
    margin-left: -2px;
    margin-right: -2px;
    font-family: "Inter-SemiBold", Helvetica;
    font-weight: 600;
    color: white;
    font-size: 6px;
    letter-spacing: -0.18px;
    line-height: 8px;
    white-space: nowrap;
  }
`;

const StyledSmallerImage = styled(Image)`
  width: 28px;
  height: 28px;
  margin-left: 8px;
`;
const StyledImage = styled(Image)`
  width: 32px;
  height: 32px;
`;
const StyledBiggerImage = styled(Image)`
  width: 56px;
  height: 56px;
`;
// ----------------------------------------------------------------------

const PrivateHeader = () => {
  const { logout }: any = useAuthContext();
  const router = useRouter();

  const user = getUser();


  const [showOffcanvasSettings, setShowOffcanvasSettings] = React.useState(false);
  const [showAuditTrail, setShowAuditTrail] = React.useState(false);


  const settingsOffcanvas = () => {
    return <Offcanvas
      backdrop={'static'}
      scroll={false}
      placement={'end'}
      show={showOffcanvasSettings}
      onHide={() => {
        setShowOffcanvasSettings(false);
      }}
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Profile setting</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div>Form</div>
      </Offcanvas.Body>
      
      <Modal.Footer className='border-top'>
        <Row className='w-100 gx-12'>
          <Col xs={true} className='text-end'>
            <Button
              variant={'custom-text'}
              size={'sm'}
              className='me-12'
              onClick={(e) => {
                setShowOffcanvasSettings(false);
              }}
            >Cancel</Button>
            <Button
              variant={'custom-primary'}
              size={'sm'}
              onClick={(e) => {
                setShowOffcanvasSettings(false);
              }}
            >Save</Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Offcanvas>
  }


  return <StyledRow id={'header_ID'} className={'g-0 align-items-center'}>
    <Col xs={'auto'} className={`d-none d-${MAIN_BREAKPOINT}-block`}>
      <Row className={'g-2 align-items-center'}
        style={{ cursor: 'pointer' }}
        onClick={() => {
          router.replace('/');
        }}
      >
        <Col xs={'auto'}><StyledSmallerImage src={DefaultImage} roundedCircle /></Col>
        <Col xs={'auto'}><small className={'fw-bold text-primary'}>TRANZPORTER</small></Col>
      </Row>
    </Col>

    <Col xs={'auto'} md={3} className={`d-none d-${MAIN_BREAKPOINT}-block`}></Col>

    <Col xs={true}>
      <Row className={'g-0 align-items-center'}>
        <Col xs={true} md={true}>
          {/* <InputGroup>
            <InputGroup.Text className='p-search'>
              <SearchIcon />
            </InputGroup.Text>
            <Form.Control
              id={'search_ID'}
              type={'text'}
              autoComplete='off'
              placeholder={'Search'}
              isInvalid={false}
            />
          </InputGroup> */}
        </Col>

        <Col xs={'auto'} md={true} className={'text-end'}>
          <Row className={'g-0 align-items-center justify-content-end'}>
            {/* <Col xs={'auto'} style={{ paddingRight: '16px' }}>
              <Dropdown>
                <Dropdown.Toggle as={CustomToggle}>
                  <StyledBell>
                    <StyledBellIcon />
                    <StyledBellLabel><div className="text-wrapper">5</div></StyledBellLabel>
                  </StyledBell>
                </Dropdown.Toggle>

                <Dropdown.Menu as={CustomMenu}>
                  <Dropdown.Item eventKey="1">Red</Dropdown.Item>
                </Dropdown.Menu> 
              </Dropdown>
            </Col> */}

            <Col xs={'auto'}>
              <Dropdown>
                <Dropdown.Toggle as={CustomToggle}>
                  <StyledImage src={DefaultImage} roundedCircle />
                </Dropdown.Toggle>

                <Dropdown.Menu as={CustomMenu}>
                  <Dropdown.Header>
                    <Stack direction={'horizontal'}>
                      <div className={'pe-3'}>
                        <StyledBiggerImage
                          src={DefaultImage}
                          roundedCircle
                          onClick={() => {
                            if(!isProduction()){
                              window.open('/design-system','_blank');
                            }
                          }}
                        />
                      </div>
                      <div>
                        <div><span className={'large fw-bold text-dark'}>{(user && user.name) ? user.name : ''}</span></div>
                        <div><small className={'text-secondary'}>{(user && user.email) ? user.email : ''}</small></div>
                      </div>
                    </Stack>
                  </Dropdown.Header>

                  <li><hr className={'my-1'} /></li>

                  {/* <Dropdown.Item
                    onClick={() => {
                      setShowOffcanvasSettings(true);
                    }}
                  >Settings</Dropdown.Item> */}
                  <Dropdown.Item
                    onClick={() => {
                      logout();
                    }}
                  >Sign out</Dropdown.Item>

                  {(!isProduction()) && <>
                    <li><hr className={'my-1'} /></li>
                    <Dropdown.Item disabled className={'text-end'}>
                      <Badge bg={'custom-danger mt-1'}>v{REACT_APP_VERSION}</Badge>
                    </Dropdown.Item>
                  </>}
                </Dropdown.Menu>
              </Dropdown>

              {settingsOffcanvas()}
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  </StyledRow>
}

export default PrivateHeader;