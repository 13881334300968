/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-loop-func */
import React from 'react';
import { createContext, useContext } from 'react';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from 'lodash';

import axios from '../../../../utils/axios';
import { successAPI, errorAPI, removeEmptyRows } from '../../../../utils/utils';
import Yup from '../../../../utils/yup';
import { MaintenanceStoreType } from 'src/utils/enums';


export const fields = {
  storeName: {
    id: 'name',
    label: 'Store',
    placeholder: 'Enter store name',
  },
};

export const formSchema = (id: number|null= null) => {
  return Yup.object().shape({
    store: Yup.array().of(Yup.object({
      name: Yup.string().nullable().label(fields.storeName.label),
    }))
    .unique('name'),
  })
}


let formikContext: any = null;
export const FormikContext = createContext<any>(null);
export const useFormikContext = () => {
    formikContext = useContext(FormikContext);
    if (!formikContext) {
      throw new Error('useFormikContext must be used within a FormikProvider');
    }
    return formikContext;
};


export const prepareForm = (store: any = null) => {
  let formStore = _.cloneDeep(store);
  
  let data: any = {
    store: [],
  }

  if(formStore){
    data['store'] = (formStore && formStore.length > 0) ? formStore : [];
  }
  data['store'].push(initialStoreItem)
  
  return data;
};
export const prepareData = (values: any = null) => {
  let data: any = [];

  if(values && values.store && values.store.length > 0){
    data = values.store.map((item: any, i: number) => {
      let itm: any = {
        name: item?.name,
        type: item?.type,
      }
      if(item.maintenanceStoreId){
        itm['maintenanceStoreId'] = item?.maintenanceStoreId;
      }

      return itm
    });
  }
  if(data && data.length > 0){
    data = removeEmptyRows(data, [ 'name' ]);
  }

  return {
    type: MaintenanceStoreType.Fuel,
    data: data,
  };
};


export interface initialValuesStruct {
  store: Array<any>,
};
export const initialValues: initialValuesStruct = {
  store: [],
};

export interface StoreItemStruct {
  maintenanceStoreId?: number|null,
  name: string,
  type: number,
};
export const initialStoreItem: StoreItemStruct = {
  maintenanceStoreId: null,
  name: '',
  type: MaintenanceStoreType.Fuel,
};


interface InitState {
  isLoading: boolean,
  show: boolean,
  details: any,
  listStore: Array<any>,

  isLoadingCreateUpdateDelete: boolean,
}

function NewReducer() {
  const name = 'fuelSettingsSlice';


  const initialState: InitState = {
    isLoading: false,
    show: false,
    details: initialValues,
    listStore: [],

    isLoadingCreateUpdateDelete: false,
  };


  const reducers = {
    resetSlice: () => {
      return initialState;
    },
    setLoading: (state: InitState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setShow: (state: InitState, action: PayloadAction<{ show: boolean }>) => {
      state.show = action.payload.show;
    },
    setValues: (state: InitState, action: PayloadAction<any>) => {
      state.details = action.payload;
    },

    startReadStore: (state: InitState) => {
      state.isLoading = true;
      state.listStore = [];
    },
    finishReadStore: (state: InitState, action: PayloadAction<any>) => {
      let data = (action.payload && action.payload.length > 0) ? action.payload : [];
      state.listStore = data;
      state.isLoading = false;
    },

    startDetailsStore: (state: InitState) => {
      state.isLoading = true;
    },
    finishDetailsStore: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.details = action.payload;
    },

    startCreateStore: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishCreateStore: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },

    startUpdateStore: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishUpdateStore: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },

    startDeleteStore: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishDeleteStore: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },
    
    startBulkStore: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishBulkStore: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },
  };


  const apis = {
    callReadStoreApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startReadStore());
  
        await axios.get('maintenance-store', { params: params }).then(result => {
            let data = (result && result.data && result.data.data && result.data.data.length > 0) ? result.data.data : [];
            
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishReadStore(data));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishReadStore(null));
        });
    },

    callDetailsStoreApi: (id: number|null, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startDetailsStore());
  
        await axios.get('maintenance-store/' + id).then(result => {
            let data = result.data;
            
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishDetailsStore(data));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishDetailsStore(null));
        });
    },

    callCreateStoreApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startCreateStore());
  
        await axios.post('maintenance-store', params).then(result => {
            let data = result.data;
            
            successAPI(data);

            let obj = (data && data.data) ? data.data : null;
            callback(true, obj);
            dispatch(actions.finishCreateStore(obj));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishCreateStore(null));
        });
    },

    callUpdateStoreApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startUpdateStore());
  
        await axios.put('maintenance-store', params).then(result => {
            let data = result.data;
            
            successAPI(data);

            let obj = (data && data.data) ? data.data : null;
            callback(true, obj);
            dispatch(actions.finishUpdateStore(obj));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishUpdateStore(null));
        });
    },

    callDeleteStoreApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startDeleteStore());
  
        await axios.delete('maintenance-store', { data: params }).then(result => {
            let data = result.data;
                
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishDeleteStore(data));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishDeleteStore(null));
        });
    },

    callBulkStoreApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startBulkStore());
  
        await axios.put('maintenance-store/bulk', params).then(result => {
            let data = result.data;
            
            successAPI(data);

            let obj = (data && data.data) ? data.data : null;
            callback(true, obj);
            dispatch(actions.finishBulkStore(obj));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishBulkStore(null));
        });
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();